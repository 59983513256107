<template>
  <div>
    <v-tooltip top>
      {{buttonText||"Nevezés"}}
      <template v-slot:activator="{on, attrs}">
        <v-btn fab :color="buttonColor||'success'" v-on="on" v-bind="attrs" @click="selectClub()"><v-icon>{{buttonIcon||"fa-plus"}}</v-icon></v-btn>
      </template>
    </v-tooltip>
    <v-dialog
      v-model="show"
      persistent
    >
      <v-card>
        <v-card-text>
          <span class="headline">Egyesület választása</span>
        </v-card-text>
        <v-card-text>
          <v-container>
            <v-select
              rounded
              outlined
              label="Egyesület"
              prepend-inner-icon="fa-map-marker"
              :items="clubs"
              item-text="name"
              item-value="id"
              v-model="clubId"
            />
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="show=false"
          >
            Bezárás
          </v-btn>
          <span v-if="clubId">
            <v-btn
              color="blue darken-1"
              text
              @click="next"
            >
              Tovább
            </v-btn>
          </span>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

export default {
  props: ['buttonColor', 'buttonText', 'buttonIcon', 'getClubFrom'],
  data: function(){
    return {
      clubId: null,
      clubs: [],
      show: false
    }
  },
  computed: {

  },
  watch: {

  },
  methods: {
    selectClub: function() {
      if(this.clubs.length==0) this.getClubs().then(() => {this.show=true})
      else this.show=true
    },
    getClubs: function() {
      return new Promise((success, error)=> {
        this.axios({url: `club/${this.getClubFrom||'competition'}`, method: 'GET'}).then(response => {
          if(response.data.success) {
            this.clubs=response.data.data.clubs
            return success()
          }
          error()
        }).catch(err => error(err))
      })
    },
    next: function() {
      this.$emit("club",this.clubId)
      this.show=false;
    }
  },
  mounted() {

  }
}


</script>
