<template>
	<div>
    <v-card>
      <v-toolbar
        flat
        color="primary"
        dark
      >
        <v-toolbar-title>{{$route.params.coordinatorView?"KOORDINÁTORI NÉZET - ":""}}{{competition.name}}</v-toolbar-title>
      </v-toolbar>
      <v-tabs :vertical="!isMobilephone" v-if="mounted" v-model="tab">
				<v-tab>
          <v-icon left>
            fa-trophy
          </v-icon>
          Verseny adatai
        </v-tab>
				<v-tab>
          <v-icon left>
            fa-user-tie
          </v-icon>
          Szervezési adatok
        </v-tab>
				<v-tab>
          <v-icon left>
            fa-dumbbell
          </v-icon>
					Gyakorlatok
        </v-tab>
				<v-tab>
          <v-icon left>
            fa-scroll
          </v-icon>
					Kiírás
        </v-tab>
				<v-tab>
          <v-icon left>
            fa-bullhorn
          </v-icon>
          Kihirdetés
        </v-tab>
				<v-tab v-if="competition.status>=1">
          <v-icon left>
            fa-hand-paper
          </v-icon>
          Nevezések
        </v-tab>
				<v-tab v-if="endedApplicationDeadline&&competition.status==1">
					<v-icon left>
						fa-calendar-alt
					</v-icon>
					Menetrend
				</v-tab>
				<v-tab v-if="endedApplicationDeadline&&competition.status==1">
					<v-icon left>
						fa-file-alt
					</v-icon>
					Jegyzőkönyvek
				</v-tab>
				<v-tab v-if="beginnedCompetition&&competition.status>0">
					<v-icon left>
						fa-user-clock
					</v-icon>
					Eredménybevitel
				</v-tab>
				<v-tab v-if="endedApplicationDeadline&&competition.status>0">
					<v-icon left>
						fa-star
					</v-icon>
					Verseny állása
				</v-tab>
				<v-tab v-if="beginnedCompetition&&competition.status>0">
					<v-icon left>
						fa-newspaper
					</v-icon>
					Beszámoló
				</v-tab>
				<v-tab-item>
					<competitionDetails :competition="competition" :editable="!$route.params.coordinatorView"/>
				</v-tab-item>
				<v-tab-item>
					<organizationalDetails :choosableOrganizers="users.filter(u => u.role>=roles.trainer&&competition.Organizer.findIndex(o =>o.id==u.id)==-1)" :competitionId="$route.params.id" :editable="competition.status==-1&&!$route.params.coordinatorView" :organizers="competition.Organizer" :organizerClub="competition.OrganizerClub" :organizerRegion="competition.Region" :level="competition.level"/>
				</v-tab-item>
				<v-tab-item>
					<exercises :editable="true" :competitionStatus="competition.status" :competitionExercises="competition.ExerciseMU_Competitions" :competitionId="$route.params.id" :competitionHelpers="$route.params.coordinatorView?false:users" :exercises="exercises" :exerciseMUs="exerciseMUs" :ageGroups="ageGroups" :measurementUnits="measurementUnits"/>
				</v-tab-item>

				<v-tab-item>
					<announcement :visitor="false" :editable="competition.status==-1" :competitionId="$route.params.id"/>
				</v-tab-item>

				<v-tab-item>
					<span v-if="(((competition.level>=competitionLevels.regional&&$store.getters.userRole>=roles.nationalCoordinator)||($store.getters.userRole<=roles.nationalCoordinator&&$store.getters.userRole>=roles.regionalCoordinator&&competition.level<competitionLevels.regional))&&competition.status<=1&&competition.status>-1&&$route.params.coordinatorView)">
						<h1>Koordinátori teendők</h1>
						<v-btn rounded color="success" :disabled="competition.status>0||endedApplicationDeadline" @click="coordinatorConfirm(true,false)"><v-icon>fa-check-circle</v-icon>Jóváhagyás</v-btn>
						<v-btn rounded color="red accent-4" :disabled="competition.status>0" @click="coordinatorConfirm(false,false)"><v-icon>fa-times-circle</v-icon>Visszaküldés javításra</v-btn>
						<v-btn rounded color="warning" :disabled="competition.status!=1" @click="sendToEdit()"><v-icon>fa-edit</v-icon>Visszaküldés szerkesztésre</v-btn>
					</span>
					<span v-else>
						<h1>Küldés jóváhagyásra</h1>
						<v-btn  rounded color="warning" :disabled="competition.status!=-1" @click="dialogs.editDetail.changedProperty='status', dialogs.editDetail.changedValue=0,dialog('confirm','announce')"><v-icon>fa-bullhorn</v-icon>{{competition.status==-1?'Küldés jóváhagyásra':(competition.status==0?'Jóváhagyásra vár': 'Jóváhagyva.')}}</v-btn>
					</span>
					<invitations v-if="$route.params.coordinatorView!==true&&$route.params.coordinatorView!=='true'" :competitionId="$route.params.id" :competitionStatus="competition.status"/>

				</v-tab-item>

				<v-tab-item v-if="competition.status>=1">
					<h1>Nevezett csapatok</h1>
					<selectClub v-if="!$route.params.coordinatorView&&competition.status==1" buttonText="Új nevezés" buttonIcon="fa-plus" buttonColor="success" getClubFrom="competition" @club="applyTeamDialog($event)"/>
					<appliedTeamDetail @newTeam="addTeam" :ageGroups="ageGroups" :competitionId="competition.id" :team="dialogs.applyTeam.team" @close="dialogs.applyTeam.show=false" :show="dialogs.applyTeam.show" :newTeam="true" :editable="true" :clubId="dialogs.applyTeam.clubId"/>

					<appliedTeams :showCoaches="true" :canDeleteTeam="!$route.params.coordinatorView&&competition.status==1" :ageGroups="ageGroups" :competitionId="$route.params.id" :editable="!$route.params.coordinatorView&&competition.status==1" :appliedTeams="appliedTeams"/>
				</v-tab-item>
				<v-tab-item v-if="endedApplicationDeadline&&competition.status==1">
					<timetable :competitionId="$route.params.id" :editable="competition.status==1&&!$route.params.coordinatorView" :teams="appliedTeams" :exerciseMUs="exerciseMUs" :exercises="exercises" :measurementUnits="measurementUnits"/>
				</v-tab-item>
				<v-tab-item v-if="endedApplicationDeadline&&competition.status==1">
					<scoreCards :competitionId="$route.params.id"/>
				</v-tab-item>
				<v-tab-item v-if="beginnedCompetition&&competition.status>0">
					<allResultLog :competitionId="$route.params.id" :coordinatorView="$route.params.coordinatorView"/>
				</v-tab-item>

				<v-tab-item v-if="beginnedCompetition&&competition.status>0">
					<competitionState :competitionId="$route.params.id"/>
				</v-tab-item>

				<v-tab-item v-if="beginnedCompetition&&competition.status>0">
					<report :editable="competition.status==1&&!$route.params.coordinatorView" :competitionId="$route.params.id"/>
					<span v-if="$store.getters.userRole>=roles.regionalCoordinator&&competition.status==2">
			      <h1>Koordinátori teendők</h1>
			      <v-btn rounded color="success" :disabled="competition.status>2" @click="coordinatorConfirm(true,true)"><v-icon>fa-check-circle</v-icon>Jóváhagyás</v-btn>
			      <v-btn rounded color="red accent-4" :disabled="competition.status>2" @click="coordinatorConfirm(false,true)"><v-icon>fa-times-circle</v-icon>Visszaküldés javításra</v-btn>
			    </span>
			    <span v-else-if="competition.status==1&&!$route.params.coordinatorView">
			      <h1>Küldés jóváhagyásra</h1>
			      <v-btn  rounded color="warning" :disabled="competition.status!=1||!beginnedCompetition" @click="dialogs.editDetail.changedProperty='status', dialogs.editDetail.changedValue=2,dialog('confirm','end')"><v-icon>fa-newspaper</v-icon>{{competition.status==1?'Küldés jóváhagyásra':(competition.status==2?'Jóváhagyásra vár': 'Jóváhagyva.')}}</v-btn>
			    </span>
				</v-tab-item>
      </v-tabs>
    </v-card>
    <template>
      <v-row justify="center">
			<v-dialog
				v-model="dialogs.confirm.show"
				persistent
				max-width="600px"
			>

				<v-card>
					<v-card-text>
						<span class="headline">{{dialogs.confirm.title}}</span>
					</v-card-text>
					<v-card-text>
						<v-container>
							{{dialogs.confirm.text}}
						</v-container>
					</v-card-text>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn
							color="blue darken-1"
							text
							@click="dialogs.confirm.show = false"
						>
							{{dialogs.confirm.cancelButton}}
						</v-btn>
						<v-btn
							color="blue darken-1"
							text
							@click="dialogs.confirm.confirmedMethod()"
						>
							{{dialogs.confirm.confirmButton}}
						</v-btn>
					</v-card-actions>
				</v-card>
		</v-dialog>
    </v-row>

  </template>

	</div>
</template>

<script>
	import router from '../router';
  import competitionLevelNames from '../plugins/competitionLevelNames';
  import roles_CompetitionLevels from '../plugins/roles-CompetitionLevels'
	import competitionLevels from '../plugins/competitionLevels'
  import competitionStatusNames from '../plugins/competitionStatusNames'
	import exercises from '../components/competitionOrganizer-Exercises'
	import invitations from '../components/competitionOrganizer-Invitations'
	import organizationalDetails from '../components/competitionOrganizer-OrganizationalDetails'
	import competitionDetails from '../components/competitionDetails'
	import appliedTeams from '../components/competitionApplication-appliedTeams'
	import timetable from '../components/competition-timetable'
	import allResultLog from '../components/competitionOrganizer-allResultLog'
	import competitionState from '../components/competition-state'
	import announcement from '../components/competition-announcement'
	import scoreCards from '../components/competitionOrganizer-scoreCards'
	import report from '../components/competition-report'
	import selectClub from '../components/selectClub'
	import appliedTeamDetail from '../components/competitionApplication-appliedTeamDetail'


	export default {
    props: ['id'],
		components: {
			exercises,
			invitations,
			competitionDetails,
			appliedTeams,
			organizationalDetails,
			timetable,
			allResultLog,
			competitionState,
			announcement,
			scoreCards,
			report,
			selectClub,
			appliedTeamDetail
		},
		data: function(){
			return {
				tab: 0,
				mounted: false,
        competitionLevelNames,
        competitionStatusNames,
				competitionLevels,
				appliedTeams: [],
        competition: {},
        users: [],
				clubs: [],
				exerciseMUs: [],
				measurementUnits: [],
				exercises: [],
				ageGroups: [],
        regions: [],
        dialogs: {
          editDetail: {
						changedProperty: '',
						changedValue: '',
					},
					confirm: {
						show: false,
						text: '',
						title: '',
						confirmedMethod: () => {},
						confirmButton: '',
						cancelButton: ''
					},
					applyTeam: {
						show: false,
						clubId: null,
		        team: {
		          name: "",
		          Coaches: [],
		          KidAthletes: [],
		          AgeGroupId: null
		        },

					}
        },
      }
		},
		watch: {
			tab(newTab) {
				if(newTab==5||newTab==6) this.getAppliedTeams();
			}
		},
		computed: {
			endedApplicationDeadline(){
				return (new Date())>this.dateParse(this.competition.applicationDeadline)
			},
			beginnedCompetition() {
				return (new Date())>this.dateParse(this.competition.date)

			}
		},
		methods: {
			coordinatorConfirm: function(confirm, end) {
				this.axios({url: `competition/${this.$route.params.id}/coordinator`, method: "PUT", data: {confirm, end}}).then(response => {
					if(response.data.success) {
						this.$store.commit('setSnack', `A ${confirm?'jóváhagyás':'visszaküldés'} sikeresen megtörtént.`)
						this.$router.push({name: 'competitionListForCoordinator'})
					}
				})
			},
			sendToEdit: function() {
				this.axios({url: `competition/${this.$route.params.id}/sendtoeditafterconfirmation`, method: "PUT"}).then(response => {
					if(response.data.success) {
						this.$store.commit('setSnack', `A visszaküldés sikeresen megtörtént.`)
						this.$router.push({name: 'competitionListForCoordinator'})
					}
				})
			},
			dialog: function(dialog, data, value) {
				switch(dialog) {
					case 'confirm':
						switch(data) {
							case 'announce':
								this.dialogs.confirm.title='Jóváhagyásra küldés'
								this.dialogs.confirm.text = "Biztosan jóvá szeretné hagyatni a versenyt? A jóváhagyást követően a verseny meghirdetésre kerül. A meghirdetést nem vonhatja vissza és a meghirdetés után csak a versenybírókat módosíthatja és új egyesületeket hívhat meg, meghívást viszont nem törölhet."
								this.dialogs.confirm.cancelButton = "Nem"
								this.dialogs.confirm.confirmButton = "Igen"
								this.dialogs.confirm.confirmedMethod = () => {this.editDetail(); this.dialogs.confirm.show=false}
								this.dialogs.confirm.show=true
							break;
							case 'end':
								this.dialogs.confirm.title='Jóváhagyásra küldés'
								this.dialogs.confirm.text = "Biztosan jóvá szeretné hagyatni a versenyt? "
								this.dialogs.confirm.cancelButton = "Nem"
								this.dialogs.confirm.confirmButton = "Igen"
								this.dialogs.confirm.confirmedMethod = () => {this.editDetail(); this.dialogs.confirm.show=false}
								this.dialogs.confirm.show=true
							break;
						}
					break;
				}
			},
      editDetail: function() {
				var changed = {competition: {}};
				changed.competition[this.dialogs.editDetail.changedProperty]=this.dialogs.editDetail.changedProperty=='date'?this.dialogs.editDetail.date.date+" "+ this.dialogs.editDetail.date.time:this.dialogs.editDetail.changedValue;
				this.axios({url: "competition/"+this.$route.params.id, method: "PUT", data: {...changed}}).then((response) => {
					if(response.data.success) {
						this.competition[this.dialogs.editDetail.changedProperty] = changed.competition[this.dialogs.editDetail.changedProperty]
						this.$store.commit('setSnack','A módosítás sikeresen megtörtént')
					}
				})
      },
      computedValue: function(value) {
        return this[value]
      },
			getAppliedTeams: function() {
				this.axios({url: `competition/${this.$route.params.id}/appliedTeams`, method: "GET"}).then(response => {
					if(response.data.success) this.appliedTeams=response.data.data.Teams;
				})
			},
			applyTeamDialog: function(clubId) {
				this.dialogs.applyTeam = {
					show: true,
					clubId,
					team: {
						name: "",
						Coaches: [],
						KidAthletes: [],
						AgeGroupId: null
					},

				}
			},
			addTeam: function() {
				this.appliedTeams.push(this.dialogs.applyTeam.team)
			}
		},
		mounted(){
			this.axios({url: "competition/"+this.$route.params.id, params: {organize: true}, method: "GET"}).then((response) => {
        if(response.data.success) {
          this.competition=response.data.data.competition;
					this.exercises = response.data.data.exercises;
					this.users=response.data.data.users;
					this.exerciseMUs=response.data.data.exerciseMUs;
					this.measurementUnits=response.data.data.measurementUnits;
					this.ageGroups=response.data.data.ageGroups;
					this.mounted=true;

        }
			})
		}
	}
</script>
