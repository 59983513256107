<template>
  <div>
    <h1>Meghívások</h1>
    <p>A meghívásokat a kihirdetés után nem törölheti, viszont új egyesületet a kihirdetésig meg tud hívni. Meghívók csak a kihirdetés után kerülnek kiküldésre</p>
    <v-data-table
      :headers="tableHeaders"
      :items="invitedClubsTableItems"
    >
      <template v-slot:top>
        <v-row v-if="competitionStatus<=1">
          <v-col align="center" justify="center" cols="12" :sm="1" class="text--secondary text-left text-right">
            Új meghívó
          </v-col>
          <v-col cols="12" :sm="5">
            <v-select
              multiple
              chips
              rounded
              outlined
              label="Egyesületek"
              prepend-inner-icon="fa-map-marker"
              :items="invitableClubs"
              v-model="invite.clubs"
              item-text="name"
              item-value="id"
            />
          </v-col>
          <v-col cols="12" :sm="5">
            <v-select
              multiple
              chips
              rounded
              outlined
              label="Régiók"
              prepend-inner-icon="fa-map-marked"
              :items="regions"
              v-model="invite.regions"
              item-text="name"
              item-value="id"
            />
          </v-col>
          <v-col cols="12" :sm="1">
            <v-tooltip top>
              Meghívók létrehozása
              <template v-slot:activator="{on, attrs}">
                <v-btn v-on="on" v-bind="attrs" fab color="primary" @click="createInvitation"><v-icon>fa-envelope-open-text</v-icon></v-btn>
              </template>
            </v-tooltip>
          </v-col>
        </v-row>
      </template>
      <template v-slot:item.delete="{item}">
        <v-btn icon color="red accent-4" @click="dialogs.deleteInvitation.clubId = item.id, dialogs.deleteInvitation.show=true;" :disabled="competitionStatus>0"><v-icon>fa-trash</v-icon></v-btn>
      </template>
    </v-data-table>
    <v-dialog
      v-model="dialogs.deleteInvitation.show"
      persistent
      max-width="600px"
    >

      <v-card>
        <v-card-text>
          <span class="headline">Meghívás törlése</span>
        </v-card-text>
        <v-card-text>
          <v-container>
            Biztosan szeretné törölni a meghívást?
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialogs.deleteInvitation.show = false"
          >
            Mégsem
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="deleteInvitation()"
          >
            Törlés
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'invitations',
  props: [
    "competitionId",
    "canDeleteInvitation",
    "competitionStatus"
  ],
  data () {
    return {
      invitedClubs: [],
      regions: [],
      clubs: [],
      tableHeaders: [
        {
          text: 'Egyesület',
          align: 'center',
          sortable: true,
          filterable: true,
          groupable: false,
          value: 'name'
        },
        {
          text: 'Régió',
          align: 'center',
          sortable: true,
          filterable: true,
          groupable: false,
          value: 'region'
        },
        {
          text: 'Törlés',
          align: 'center',
          sortable: false,
          filterable: false,
          groupable: false,
          value: 'delete'
        }
      ],
      dialogs: {
        deleteInvitation: {
          show: false,
          clubId: undefined
        }
      },
      invite: {
        clubs: [],
        regions: []
      }
    }
  },
  computed: {
    invitedClubsTableItems() {
      return this.invitedClubs.map(iC => {
        const R = this.regions.find(r => r.id==iC.RegionId)
        return {
          name: iC.name,
          id: iC.id,
          region: R!==null?R.name:null
        }
      })
    },
    invitableClubs() {
      var iCs = []
      this.clubs.forEach(C => {
        if(this.invitedClubs.filter(iC => iC.id==C.id).length==0) {
          const R = this.regions.find(r => r.id==C.RegionId)
          iCs.push({
            name: `${C.name}${R!==undefined?' ('+R.name+")":null}`,
            id: C.id,
          })
        }
      })
      return iCs;
    }
  },
  methods: {
    createInvitation: function() {
      this.axios({url: `competition/${this.competitionId}/invitation`, method: "POST", data: {invitation: {...this.invite}}}).then(response => {
        if(response.data.success) {
          this.invite.clubs=[];
          this.invite.regions=[];
          response.data.data.invitedClubs.forEach(iC => {
            this.invitedClubs.push(this.clubs.find(C => C.id==iC))
          })
          this.$store.commit('setSnack','A meghívók létrehozása megtörtént.')
        }
      })
    },
    deleteInvitation: function() {
      this.axios({url: `competition/${this.competitionId}/invitation/${this.dialogs.deleteInvitation.clubId}`, method:"DELETE"}).then(response => {
        if(response.data.success) {
          this.dialogs.deleteInvitation.show=false;
          this.invitedClubs.splice(this.invitedClubs.findIndex(iC => iC.id==this.dialogs.deleteInvitation.clubId),1)
          this.$store.commit('setSnack', 'A törlés sikeresen megtörtént')
        }
      })
    },
    getInvitationsAndRegions: function() {
      this.axios({url: `competition/${this.competitionId}/invitationsAndRegions`, method: "GET"}).then(response => {
        if(response.data.success){
          this.invitedClubs=response.data.data.invitedClubs;
          this.regions=response.data.data.regions;
        }
      })
    },
    getClubs: function() {
      this.axios({url: `competition/clubs`, method: "GET"}).then(response => {
        if(response.data.success) this.clubs=response.data.data.clubs
      })
    }
  },
  mounted() {
    this.getInvitationsAndRegions();
    this.getClubs();
  }
}
</script>
