<!--Todo: ISKOLA-->

<template>
  <div>
    <v-tabs>
      <v-tab v-for="aG in ageGroups" :key="aG.id">{{aG.shortname}} - {{aG.name}}</v-tab>

      <v-tab-item v-for="aG in ageGroups" :key="aG.id">
        <v-tabs>
          <v-tab v-for="eMUC in aG.ExerciseMU_Competitions" :key="eMUC.id">
            {{eMUC.Exercise_MeasurementUnit.Exercise.name}}
          </v-tab>

          <v-tab-item v-for="eMUC in aG.ExerciseMU_Competitions" :key="eMUC.id">
            <CresultLog :EMUCId="eMUC.id" :noTitle="true" :coordinatorView="coordinatorView"/>
          </v-tab-item>
        </v-tabs>
      </v-tab-item>
    </v-tabs>

  </div>
</template>

<script>
import CresultLog from './competition-resultLog'
export default {
  props: ['competitionId','coordinatorView'],
  components: {
    CresultLog
  },
  data: function(){
    return {
      ageGroups: []
    }
  },
  computed: {

  },
  watch: {

  },
  methods: {
    getTimeTable: function(){
      this.axios({url: `competition/${this.competitionId}/ageGroupsAndEMUCs`, method: 'GET'}).then(response => {
        if(response.data.success) this.ageGroups=response.data.data.ageGroups
      })
    },
    exerciseTabName: function(eMUC) {
        const eMU = this.exerciseMUs.find(EMU => EMU.id==eMUC.ExerciseMeasurementUnitId);
        const e = eMU!==undefined?this.exercises.find(E => E.id==eMU.ExerciseId):null;
        const mU = eMU!==undefined?this.measurementUnits.find(MU => MU.id==eMU.MeasurementUnitId):null;
        return `${e!==null&&e!==undefined?e.name:''}${mU!==null&&mU!==undefined?' ('+mU.unit+')':''} - ${eMUC.maxTeamInOneTime} pálya`
    }
  },
  mounted() {
    this.getTimeTable()
  }
}


</script>
