<template>
  <div>
    <h1>Minden versenyszám jegyzőkönyve zip-ben</h1>
    <v-btn color="primary" rounded :href="`${this.axios.defaults.baseURL}competition/${competitionId}/docx/allScoreCard`" target="_blank"><v-icon>fa-download</v-icon>Jegyzőkönyvek letöltése</v-btn>
  </div>
</template>

<script>
export default {
  name: 'scoreCard',
  props: [
    "competitionId",
  ],
  data () {
    return {

    }
  },
  computed: {

  },
  methods: {

  },
  mounted() {
  }
}
</script>
