<!--Todo: ISKOLA-->

<template>
  <div>
    <v-btn
      v-if="editable"
      rounded
      color="primary"
      @click="autoTimetable"
    ><v-icon>fa-magic</v-icon>Automatikus menetrend készítése</v-btn>
    <v-btn
      color="success"
      rounded
      :href="`${this.axios.defaults.baseURL}competition/${competitionId}/docx/timetable`" target="_blank"
    >
      <v-icon>fa-download</v-icon>
      Letöltés
    </v-btn>
    <v-tabs icons-and-text>
      <v-tab>
        Gyakorlatonként
        <v-icon>
          fa-dumbbell
        </v-icon>
      </v-tab>
      <v-tab>
        Csapatonként
        <v-icon>
          fa-users
        </v-icon>
      </v-tab>

      <v-tab-item> <!--Gyakorlatonként-->
        <v-tabs>
          <v-tab v-for="aG in timetable" :key="aG.id">{{aG.shortname}} - {{aG.name}}</v-tab>

          <v-tab-item v-for="aG in timetable" :key="aG.id">
            <v-tabs>
              <v-tab v-for="eMUC in aG.ExerciseMU_Competitions" :key="eMUC.id">
                {{exerciseTabName(eMUC)}}
              </v-tab>

              <v-tab-item v-for="eMUC in aG.ExerciseMU_Competitions" :key="eMUC.id">
                <v-data-table
                  :headers="tables.exerciseView.teams.headers"
                  :items="getTeamsToExercises(aG.id, eMUC.id)"
                  disable-pagination
                  hide-default-footer
                >
                  <template v-slot:item.time="{item}">
                    <v-text-field
                      :value="item.time"
                      rounded
                      outlined
                      :readonly="!editable"
                      label="Időpont"
                      @change="changeTime(item.teamId, aG.id, eMUC.id, item.teamInEMUC, $event)"
                      :rules="[scheduledTime(item.scheduledTimes),fieldRules['isHH:MM']]"
                      maxlength="5"
                      counter="5"
                    />
                  </template>
                </v-data-table>
              </v-tab-item>
            </v-tabs>
          </v-tab-item>
        </v-tabs>
      </v-tab-item> <!--/Gyakorlatonként-->

      <v-tab-item> <!--Csapatonként-->
        <p>Szerkeszteni a gyakorlatonkénti nézetben tudja</p>
        <v-tabs>
          <v-tab v-for="aG in timetable" :key="aG.id">{{aG.shortname}} - {{aG.name}}</v-tab>

          <v-tab-item v-for="aG in timetable" :key="aG.id">
            <v-tabs>
              <v-tab v-for="T in teams.filter(t => t.AgeGroupId==aG.id)" :key="T.id">{{T.name}}{{T.KAClub!==null&&T.KAClub!==undefined?` - ${T.KAClub.MIRClub.name}`:''}}</v-tab>

              <v-tab-item v-for="T in teams.filter(t => t.AgeGroupId==aG.id)" :key="T.id">
                <v-data-table
                  :headers="tables.teamView.exercises.headers"
                  :items="getExercisesToTeams(aG.id, T.id)"
                  disable-pagination
                  hide-default-footer
                >
                  <template v-slot:item.time="{item}">
                    <v-text-field
                      :value="item.time"
                      :readonly="!editable"
                      rounded
                      outlined
                      label="Időpont"
                      @change="changeTime(T.id, aG.id, item.eMUCId, item.teamInEMUC, $event)"
                      :rules="[scheduledTime(item.scheduledTimes),fieldRules['isHH:MM']]"
                      maxlength="5"
                      counter="5"
                    />
                  </template>
                </v-data-table>
              </v-tab-item>
            </v-tabs>
          </v-tab-item>
        </v-tabs>
      </v-tab-item> <!--/Csapatonként-->
    </v-tabs>
  </div>
</template>

<script>
export default {
  props: ['competitionId', 'editable', 'exerciseMUs', 'exercises', 'measurementUnits', 'teams'],
  components: {
  },
  data: function(){
    return {
      timetable: [],
      tables: {
        exerciseView: {
          teams: {
            headers: [
              {
                text: 'Egyesület/iskola',
                align: 'center',
                sortable: true,
                filterable: false,
                groupable: false,
                value: 'clubOrSchool'
              },
              {
                text: 'Csapatnév',
                align: 'center',
                sortable: true,
                filterable: false,
                groupable: false,
                value: 'name'
              },
              {
                text: 'Foglalt időpontok',
                align: 'center',
                sortable: false,
                filterable: false,
                groupable: false,
                value: 'scheduledTimes'
              },
              {
                text: 'Időpont',
                align: 'center',
                sortable: false,
                filterable: false,
                groupable: false,
                value: 'time'
              }
            ]
          }
        },
        teamView: {
          exercises: {
            headers: [
              {
                text: 'Gyakorlat',
                align: 'center',
                sortable: true,
                filterable: false,
                groupable: false,
                value: 'exercise'
              },
              {
                text: 'Foglalt időpontok',
                align: 'center',
                sortable: false,
                filterable: false,
                groupable: false,
                value: 'scheduledTimes'
              },
              {
                text: 'Időpont',
                align: 'center',
                sortable: false,
                filterable: false,
                groupable: false,
                value: 'time'
              }
            ]
          }
        }
      }
    }
  },
  computed: {

  },
  watch: {

  },
  methods: {
    scheduledTime: function(scheduledTimes) {
      return value => (scheduledTimes.search(value)==-1||value.length<5)||"Ez az időpont már foglalt a csapatnál."
    },
    changeTime: function(teamId, ageGroupId, eMUCriteriaId, teamInEMUC, event) {
      var startTime = event;
      if(this.fieldRules['isHH:MM'](startTime)===true) {
        if(!teamInEMUC) {
          this.axios({url: `competition/${this.competitionId}/timetable/${eMUCriteriaId}/${teamId}`, method: 'POST', data: {startTime}}).then(response =>{
            if(response.data.success) {
              const timetable = this.timetable.find(tt => tt.id==ageGroupId)
              const EMUC = timetable!==null&&timetable!==undefined?((timetable.ExerciseMU_Competitions instanceof Array)?timetable.ExerciseMU_Competitions.find(emuc => emuc.id==eMUCriteriaId):null):null
              EMUC.Teams.push({
                id: teamId,
                CompetitionTimetable: {
                  startTime
                }
              })
              this.$store.commit('setSnack', 'Mentve')
            }
          })
        }
        else {
          this.axios({url: `competition/${this.competitionId}/timetable/${eMUCriteriaId}/${teamId}`, method: 'PUT', data: {startTime}}).then(response =>{
            if(response.data.success) {
              const timetable = this.timetable.find(tt => tt.id==ageGroupId)
              const EMUC = timetable!==null&&timetable!==undefined?((timetable.ExerciseMU_Competitions instanceof Array)?timetable.ExerciseMU_Competitions.find(emuc => emuc.id==eMUCriteriaId):null):null
              var T = EMUC.Teams.find(t => t.id==teamId)
              if(T!==null&&T!==undefined) {
                if(T.CompetitionTimetable!==null&&T.CompetitionTimetable!==undefined){
                  T.CompetitionTimetable.startTime=startTime
                  this.$store.commit('setSnack', 'Mentve')
                }
              }
            }
          })
        }
      }

    },
    getExercisesToTeams: function(ageGroupId, teamId) {
      var timetable = this.timetable.find(tt => tt.id==ageGroupId);
      return timetable.ExerciseMU_Competitions.map(EMUC => {
        var response = {
          eMUCId: EMUC.id
        }
        const eMU = this.exerciseMUs.find(EMU => EMU.id==EMUC.ExerciseMeasurementUnitId);
        const e = eMU!==undefined?this.exercises.find(E => E.id==eMU.ExerciseId):null;
        const mU = eMU!==undefined?this.measurementUnits.find(MU => MU.id==eMU.MeasurementUnitId):null;
        response.exercise = `${e!==null&&e!==undefined?e.name:''}${mU!==null&&mU!==undefined?' ('+mU.unit+')':''}`
        const team = EMUC.Teams.find(T => T.id==teamId)
        response.time=team!==null&&team!==undefined?team.CompetitionTimetable.startTime:null,
        response.teamInEMUC=team!==null&&team!==undefined;
        response.scheduledTimes=EMUC.Teams.filter(T => T.id!=teamId).map(T=> T.CompetitionTimetable.startTime).join(', ')
        return response;
      }).sort((a,b) =>{
        if(a.time<b.time) return -1;
        else if(a.time>b.time) return 1;
        else return 0;
      })
    },
    getTeamsToExercises: function(ageGroupId, eMUCriteriaId) {
      return this.teams.filter(t => t.AgeGroupId==ageGroupId).map(t => {
        var response = {
          name: t.name,
          AgeGroupId: t.AgeGroupId,
          clubOrSchool: t.KAClub!==null?t.KAClub.MIRClub.name:'',
          teamId: t.id
        }
        const timetable = this.timetable.find(tt => tt.id==ageGroupId)
        const allEMUC = timetable!==null&&timetable!==undefined?((timetable.ExerciseMU_Competitions instanceof Array) ?timetable.ExerciseMU_Competitions.map(emuc => {
          const team = emuc.Teams.find(T => T.id==t.id)
          return {
            startTime: team!==null&&team!==undefined?team.CompetitionTimetable.startTime:null,
            teamInEMUC: team!==null&&team!==undefined,
            EMUCId: emuc.id
          }
        }) :null):null
        const EMUC = allEMUC!==null&&allEMUC!==undefined? allEMUC.find(emuc => emuc.EMUCId==eMUCriteriaId):null
        response.time = EMUC!==null&&EMUC!==undefined?EMUC.startTime:null
        response.teamInEMUC = EMUC!==null&&EMUC!==undefined?EMUC.teamInEMUC:false
        response.scheduledTimes=allEMUC!==null&&allEMUC!==undefined?allEMUC.filter(emuc => emuc.EMUCId!=eMUCriteriaId&&emuc.teamInEMUC).map(emuc => emuc.startTime).join(', '):null
        return response
      }).sort((a,b) =>{
        if(a.time<b.time) return -1;
        else if(a.time>b.time) return 1;
        else return 0;
      });

    },
    getTimeTable: function(){
      this.axios({url: `competition/${this.competitionId}/timetable`, method: 'GET'}).then(response => {
        if(response.data.success) this.timetable=response.data.data.timetable
      })
    },
    autoTimetable: function() {
      this.axios({url: `competition/${this.competitionId}/timetable/auto`, method: 'GET'}).then(response => {
        if(response.data.success) this.getTimeTable();
      })
    },
    exerciseTabName: function(eMUC) {
        const eMU = this.exerciseMUs.find(EMU => EMU.id==eMUC.ExerciseMeasurementUnitId);
        const e = eMU!==undefined?this.exercises.find(E => E.id==eMU.ExerciseId):null;
        const mU = eMU!==undefined?this.measurementUnits.find(MU => MU.id==eMU.MeasurementUnitId):null;
        return `${e!==null&&e!==undefined?e.name:''}${mU!==null&&mU!==undefined?' ('+mU.unit+')':''} - ${eMUC.maxTeamInOneTime} pálya`
      }
  },
  mounted() {
    this.getTimeTable()
  }
}


</script>
